"use client";

import cn from "@/utils/cn";
import Image from "next/image";
import { useRef, useState } from "react";
import Phase3Poster from "@/public/assets/images/amen-estate-phase-3-video-poster.jpg";

export default function VideoPlayer() {
  const [isPosterShown, setIsPosterShown] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlayButtonClick = () => {
    setIsPosterShown(false);
    setIsPaused(false);
    videoRef?.current?.play();
  };

  const handlePauseButtonClick = () => {
    setIsPaused(true);
    videoRef?.current?.pause();
  };

  const handleVideoEnded = () => {
    setIsPosterShown(true);
  };

  return (
    <section className="max-w-[800px] rounded-2xl p-1 mx-auto bg-gradient-to-br from-gray-200/50 to-gray-200/80">
      <section className="overflow-hidden rounded-xl aspect-[16/9] relative">
        <video
          width="640"
          height="360"
          ref={videoRef}
          preload="none"
          className="w-full h-full"
          onEnded={handleVideoEnded}
          autoPlay={false}
        >
          <source
            src="/assets/videos/amen-estate-phase-3-video.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <aside
          className={cn(
            "absolute w-full h-full top-0 left-0 transition-all duration-300",
            "flex items-center justify-center opacity-100",
            isPosterShown ? "opacity-100" : "opacity-0",
            "hover:opacity-100",
          )}
        >
          {isPosterShown || isPaused ? (
            <button
              aria-label="play video"
              onClick={handlePlayButtonClick}
              className={cn(
                "z-10 bg-white p-4 rounded-full transition-all",
                "focus:outline-none shadow-md opacity-60 hover:opacity-100",
                (isPosterShown || isPaused) && "opacity-100",
              )}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 md:h-10 w-6 md:w-10 text-gray-800"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M6 4l10 6-10 6V4z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          ) : (
            <button
              aria-label="pause video"
              onClick={handlePauseButtonClick}
              className={cn(
                "z-10 bg-white p-4 rounded-full transition-all",
                "focus:outline-none shadow-md opacity-60 hover:opacity-100",
                isPosterShown && "opacity-100",
              )}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 md:h-10 w-6 md:w-10 text-gray-800"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5 4h3v12H5V4zm7 0h3v12h-3V4z"
                />
              </svg>
            </button>
          )}

          {isPosterShown && (
            <Image
              width={640}
              height={360}
              className="absolute top-0 left-0 w-full h-full"
              alt="Rent hero image"
              src={Phase3Poster}
            />
          )}
        </aside>
      </section>
    </section>
  );
}
