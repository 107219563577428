import(/* webpackMode: "eager" */ "/builds/amen-estate/amen-estate-website/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/builds/amen-estate/amen-estate-website/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/builds/amen-estate/amen-estate-website/public/assets/images/buy-a-property.png");
;
import(/* webpackMode: "eager" */ "/builds/amen-estate/amen-estate-website/public/assets/images/get-started.jpg");
;
import(/* webpackMode: "eager" */ "/builds/amen-estate/amen-estate-website/public/assets/images/happy-home-owners.png");
;
import(/* webpackMode: "eager" */ "/builds/amen-estate/amen-estate-website/public/assets/images/home-our-estates.jpg");
;
import(/* webpackMode: "eager" */ "/builds/amen-estate/amen-estate-website/public/assets/images/rent-a-property.png");
;
import(/* webpackMode: "eager" */ "/builds/amen-estate/amen-estate-website/public/assets/images/schedule-inspection.png");
;
import(/* webpackMode: "eager" */ "/builds/amen-estate/amen-estate-website/public/assets/images/shortlet.png");
;
import(/* webpackMode: "eager" */ "/builds/amen-estate/amen-estate-website/public/assets/images/total-apartments.png");
;
import(/* webpackMode: "eager" */ "/builds/amen-estate/amen-estate-website/public/assets/images/total-constructions.png");
;
import(/* webpackMode: "eager" */ "/builds/amen-estate/amen-estate-website/public/assets/images/total-estates.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/amen-estate/amen-estate-website/src/app/_components/video-player/index.tsx");
